<template>
  <v-layout class="d-flex column">
    <app-loader
      title="Загрузка данных cписка новостей"
      :loading="!pageLoaded"
    />
    <v-layout
      v-show="pageLoaded"
      class="d-flex justify-center"
      column
    >
      <v-card
        v-for="(item, index) in news"
        :key="index"
        class="my-2 pa-2"
        :class="[{ 'unreadedPost': !item.isReaded }, 'my-2', 'pa-2']"
        style="height: 250px; max-height: 250px"
      >
        <v-row>
          <v-col cols="4">
            <v-img
              :src="`${$url}${item.posterPath}`"
              max-height="235"
            />
          </v-col>
          <v-col cols="8">
            <v-row class="d-flex flex-column">
              <v-col>
                <v-row>
                  <h1 class="h3 mb-2 pl-3">
                    {{ item.title }}
                  </h1>
                  <v-spacer />
                  <span class="ma-2">{{ item.createdAt | date }}</span>
                </v-row>
              </v-col>
              <v-col class="d-flex justify-start py-0">
                <span v-html="item.summary" />
              </v-col>
              <v-col class="d-flex justify-start align-end py-0">
                <v-btn
                  text
                  color="primary"
                  @click="$router.push({ name: 'news', params: { link: item.link } })"
                >
                  Читать полностью
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <div class="text-center">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
          color="primary"
        />
      </div>
    </v-layout>
  </v-layout>
</template>

<script>
import * as newsApi from "../../backend/newsApi.js";

export default {
  data() {
    return {
      news: [],
      totalItems: 0,
      loading: false,
      unreadedPost: "unreadedPost",
      store: "news",
      pageLoaded: false,
    };
  },
  computed: {
    rowsCount() {
      if (this.news.length !== 0) {
        return Math.floor((window.innerHeight - 190) / 250);
      } else {
        return Math.floor((window.innerHeight - 190) / 250);
      }
    },
    pages() {
      if (!this.pagination || this.pagination.rowsPerPage === null ||
        this.totalItems === null) {
        return 0;
      }
      return Math.ceil(this.totalItems / this.pagination.rowsPerPage);
    },
    items() {
      return this.$store.getters[`${this.store}/items`];
    },
    pagination: {
      get() {
        return this.$store.getters[`${this.store}/pagination`];
      },
      set(pagination) {
        this.$store.commit(`${this.store}/setPagination`, pagination);
      },
    },
  },
  watch: {
    pagination: {
      handler() {
        if (!this.loading) {
          this.getNews();
        }
      },
      deep: true,
    },
  },
  created() {
    setTimeout(() => {
      this.markAsReaded();
    }, 2000);
    this.pagination.rowsPerPage = this.rowsCount;
  },
  methods: {
    async getNews() {
      this.pageLoaded = false;
      const page = this.pagination.page;
      const rowsPerPage = this.pagination.rowsPerPage;
      const res = await newsApi.getNewsList(page, rowsPerPage);
      this.news = res.result;
      this.totalItems = res.totalItems;

      this.pageLoaded = true;
    },
    async markAsReaded() {
      const unreadedPosts = this.news.filter(n => !n.isReaded);
      const idList = unreadedPosts.map(p => p.id);
      await newsApi.markAsReaded({ value: idList });
      await this.getNews();
    },
  },
};
</script>

<style scoped>
.unreadedPost {
  border: 2px solid #005eb6
}
</style>